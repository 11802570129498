@media (max-width: 1024px) {
  .hidden-on-mobile {
    display: none;
  }

  .no-hover-mobile {
    &:hover {
      color: inherit !important;
    }
  }
}
